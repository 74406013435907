export const VERSION = '12.18.23.101';
export const API_VERSION = '1.1.23.104';
export const COPYRIGHT = '2024 © Outsource Wireless';
export const ENDPOINTS = {
	// local dev
	//API: 'http://localhost:5001/api',
	//CALL_BACK_URL: 'http://localhost:3000/update-password'

	// dev
	API: 'https://dsp-verizon-api.azurewebsites.net/api',
	CALL_BACK_URL: 'https://outsourcewireless.com/update-password'


	// prod
	//API: 'https://api.dsp-wireless.com/api',
	//CALL_BACK_URL: 'https://prodapi.caa-apps.com/reset-password's
};


// device, accessories, mobile broadbad & routers, tablets

export const RECAPTCHA = '6LfQjqsoAAAAALJ4f7oZEH-beptTqoQkkzoY2wqb';


export const SITE = 'DSP';

export const API_METHOD = {
	POST: 'POST',
	GET: 'GET',
	DELETE: 'DELETE'
};
export const LOCAL_STORAGE = {
	PROFILE: 'wbc-profile',
	TOKEN: 'wbc-token',
	CART: 'wbc-cart',
};
export const SESSION_STORAGE = {
	CUSTOMER_ID: 'customer-id',
	AGENT_ID: 'agent-id',
	TOKEN: 'live-token'
};
export const STATUS = {
	ACTIVE: { ID: 1, VALUE: 'Active' },
	INACTIVE: { ID: 0, VALUE: 'Inactive' },
};

// purchase type
export const PURCHASE_TYPES = [
	{ value: "Subsidy", label: 'Subsidy (24 month)' },
	{ value: "EPP Financing", label: 'EPP Financing' },
	{ value: "Full Price", label: 'Full Price' }
];

// installed app options
export const INSTALLED_APPS = ["Ementor", "Google Maps", "Paycom", "Waze", "Flex", "Chime", "ADP", "Amazon A to Z"];