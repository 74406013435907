// @flow
import React, { useEffect } from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import Logo from '../../assets/images/image001.jpg';
import NavBar from './NavBar';


const AccountLayout = ({ bottomLinks, children }) => {
    const currentYear = new Date().getFullYear();

    useEffect(() => {
        if (document.body) document.body.classList.add('authentication-bg');

        return () => {
            if (document.body) document.body.classList.remove('authentication-bg');
        };
    }, []);

    return (
        <>
            <div style={{ backgroundColor: "#cd040b" }}>
                <NavBar />
            </div>

            <div className="account-pages pt-2 pt-sm-5 pb-4 pb-sm-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5} xxl={4}>
                            <Card>
                                {/* logo */}
                                <Card.Header className="pt-4 pb-4 text-center" style={{ backgroundColor: '#ffffff' }}>
                                    <Link to="/">
                                        <span>
                                            <img src={Logo} alt="" height="62" />
                                        </span>
                                    </Link>
                                </Card.Header>
                                <Card.Body className="p-4">{children}</Card.Body>
                            </Card>

                            {/* bottom links */}
                            {bottomLinks}
                        </Col>
                    </Row>
                </Container>
            </div>
            <footer className="footer footer-alt">{currentYear} © Outsource Wireless</footer>
        </>
    );
};

export default AccountLayout;
